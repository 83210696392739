import { Box, Grid2 as Grid, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CandyPage } from "../../components/layout/CandyPage";

export const DASHBOARD_PAGE_ROUTE = "/";

export const DashboardPage = () => {
  return (
    <CandyPage title="Dashboard" skeleton={<PageSkeleton />}>
      <InnerDashboardPage />
    </CandyPage>
  );
};

const InnerDashboardPage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography>{t("What should we have here???")}</Typography>
    </Box>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(10)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  confirmText: string;
  onConfirm: () => void;
  open: boolean;
  onClose: () => void;
}

export const ConfirmReconcileDialog = ({
  confirmText,
  onConfirm,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("Please confirm")}</DialogTitle>

        <DialogContent>{confirmText}</DialogContent>

        <DialogActions>
          <Button autoFocus onClick={onClose}>
            {t("Cancel")}
          </Button>
          {/* TODO: Enable when api supports both ean and articleId */}
          <Button onClick={handleConfirm} disabled>
            {t("Ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { useRecoilState, useRecoilValue } from "recoil";
import { CandyPage } from "../../../components/layout/CandyPage";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ConfirmFab,
  ConfirmIconButton,
} from "../../../components/ConfirmButton";
import { useSnackBar } from "../../../hooks/useSnackbar";
import { Scanner } from "../../../components/Scanner";
import { stockListCountSelector, stockState } from "../../../state/stockState";
import { useAddToStockList } from "../../../hooks/useAddToStockList";
import { Html5QrcodeResult } from "html5-qrcode";
import { CandyArticle, candyAPI } from "../../../api/CandyAPI";
import { useFeedback } from "../../../hooks/useFeedback";
import { useCallback, useMemo, useRef, useState } from "react";
import { DevButtons } from "../../../components/DevButtons";
import { useRemoveFromStockList } from "../../../hooks/useRemoveFromStockList";
import RemoveIcon from "@mui/icons-material/Remove";
import { inventoryAPI } from "../../../api/InventoryAPI";
import { motion } from "framer-motion";
import {
  SCAN_ALERT_DURATION,
  ScanAlert,
  ScanAlertEntry,
  ScanAlertRef,
} from "../../../components/ScanAlert";
import { useTranslation } from "react-i18next";

export const INVENTORY_BULK_ADD_PAGE = "/inventory/bulk-add";

const TIMEOUT = 1000;

export const InventoryBulkAddPage: React.FunctionComponent = () => {
  const [{ items }, setItems] = useRecoilState(stockState);
  const itemCount = useRecoilValue(stockListCountSelector);
  const addArticle = useAddToStockList();
  const removeArticle = useRemoveFromStockList();
  const { showSnackBar } = useSnackBar();
  const feedback = useFeedback();
  const [throttled, setThrottled] = useState(false);
  const [latestArticles, setLatestArticles] = useState<ScanAlertEntry[]>([]);
  const scanAlertRef = useRef<ScanAlertRef>(null);
  const theme = useTheme();
  const { t } = useTranslation();

  const itemsSortedByUpdated = useMemo(
    () => [...items].sort((a, b) => b.updated - a.updated),
    [items]
  );

  const itemsNotInLatest = useMemo(
    () =>
      itemsSortedByUpdated.filter(
        (item) =>
          !latestArticles.some(
            (article) => article.articleId === item.articleId
          )
      ),
    [itemsSortedByUpdated, latestArticles]
  );

  const handleSave = useCallback(() => {
    const batch = items.map((item) => ({
      articleId: item.articleId,
      count: item.quantity,
    }));
    inventoryAPI
      .moveBatchToInventory(batch)
      .then(() => {
        showSnackBar(
          t("Added {{quantity}} items to inventory", { quantity: itemCount }),
          "success"
        );
        setItems((oldState) => ({
          ...oldState,
          items: [],
        }));
      })
      .catch(() => {
        showSnackBar(
          t("Failed to add {{quantity}} items to inventory", {
            quantity: itemCount,
          }),
          "error"
        );
      });
  }, [itemCount, items, setItems, showSnackBar, t]);

  const handleAdd = useCallback(
    (article: CandyArticle) => {
      addArticle(
        {
          ean: article.ean,
          articleId: article.articleId,
          displayName: article.displayName,
          candyType: article.candyType,
        },
        1
      );

      setLatestArticles((oldArticles) => [
        ...oldArticles,
        {
          ean: article.ean,
          articleId: article.articleId,
          displayName: article.displayName,
        },
      ]);

      setTimeout(() => {
        setLatestArticles((oldArticles) => oldArticles.slice(1));
      }, SCAN_ALERT_DURATION);

      scanAlertRef.current?.({
        ean: article.ean,
        articleId: article.articleId,
        displayName: article.displayName,
      });
    },
    [addArticle]
  );

  const onScanSuccess = useCallback(
    (decodedText: string, decodedResult: Html5QrcodeResult) => {
      if (throttled) {
        return;
      }

      console.info(`Code matched = ${decodedText}`, decodedResult);

      setThrottled(true);
      feedback();
      candyAPI
        .loadArticleByEan(decodedText)
        .then((article) => {
          handleAdd(article);
        })
        .catch(() => {
          scanAlertRef.current?.({
            ean: decodedText,
          });
        })
        .finally(() => {
          setTimeout(() => {
            setThrottled(false);
          }, TIMEOUT);
        });
    },
    [feedback, handleAdd, throttled]
  );

  return (
    <CandyPage fullHeight title="Scan inventory">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            flex: "1.25 0 0px",
            position: "relative",
            display: "flex",
            maxHeight: "60%",
            "&:after": (theme) => ({
              content: '""',
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              height: 80,
              background: `linear-gradient(to top, ${theme.palette.background.paper} 0%, transparent 100%)`,
            }),
          }}
        >
          <TableContainer
            sx={{
              height: "100%",
              position: "relative",
              paddingBottom: 8,
              flex: 1,
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Name")}</TableCell>
                  <TableCell align="right">{t("Quantity")}</TableCell>
                  <TableCell align="right">{t("Action")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      <Typography variant="body1">
                        {t("Nothing added to list")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}

                {itemsNotInLatest.map((candy) => (
                  <TableRow
                    key={candy.articleId}
                    hover
                    component={motion.tr}
                    layoutId={candy.articleId}
                    layout
                    animate={{
                      background: [
                        "#00000000",
                        theme.palette.success.main,
                        "#00000000",
                      ],
                      transition: {
                        duration: 1,
                      },
                    }}
                  >
                    <TableCell>{candy.displayName}</TableCell>
                    <TableCell align="right">{candy.quantity}</TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      <ConfirmIconButton
                        color="error"
                        confirmText={t("Remove one {{article}} from list?", {
                          article: candy.displayName,
                        })}
                        onConfirm={() => {
                          removeArticle(candy.articleId);
                          showSnackBar(
                            t("Removed {{article}} from stock add list", {
                              article: candy.displayName,
                            }),
                            "info"
                          );
                        }}
                      >
                        <RemoveIcon />
                      </ConfirmIconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {items.length > 0 && (
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                bottom: 0,
                pb: 2,
              }}
            >
              <ConfirmFab
                variant="extended"
                size="medium"
                color="secondary"
                confirmText={t(
                  "Are you sure you want to add all {{quantity}} items to stock?",
                  { quantity: itemCount }
                )}
                onConfirm={handleSave}
              >
                {t("Add {{quantity}} items to stock", { quantity: itemCount })}
              </ConfirmFab>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flex: ".75 1 0px",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: "relative",
            background: (theme) => theme.palette.action.focus,
          }}
        >
          <ScanAlert ref={scanAlertRef} />
          <DevButtons onScanSuccess={onScanSuccess} />
          <Scanner
            onScanSuccess={onScanSuccess}
            onScanError={() => {
              // TODO: Show error
            }}
            style={{
              flex: 1,
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
    </CandyPage>
  );
};

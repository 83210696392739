import { FunctionComponent, useState } from "react";
import { CandySearch, candyAPI } from "../api/CandyAPI";
import {
  Autocomplete,
  Box,
  ListItemText,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

interface Props {
  onSearch: (result: CandySearch[]) => void;
  sx?: SxProps<Theme>;
}

export const Search: FunctionComponent<Props> = ({ onSearch, sx }) => {
  const [search, setSearch] = useState<string>("");
  const { t } = useTranslation();
  const [debouncedSearch] = useDebounce<typeof search>(search, 250);

  const {
    data: results,
    isLoading,
    isFetched,
    isError,
    refetch: triggerSearch,
  } = useQuery({
    ...candyAPI.searchArticle(debouncedSearch),
    enabled: debouncedSearch.length > 0,
    placeholderData: keepPreviousData,
  });

  return (
    <Box sx={sx}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          triggerSearch();
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            gap: 2,
            p: 2,
          }}
        >
          <Autocomplete
            freeSolo
            autoHighlight
            openOnFocus
            disableCloseOnSelect
            filterOptions={(options) => {
              return options;
            }}
            options={
              debouncedSearch && results && results.length > 0 ? results : []
            }
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            renderOption={(props, option) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Box {...props}>
                <ListItemText
                  primary={option.name}
                  secondary={option.translationName ?? "-"}
                />
              </Box>
            )}
            onChange={(_, value) => {
              if (!value || typeof value === "string") {
                return;
              }
              onSearch([value]);
            }}
            sx={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Search")}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    endAdornment: (
                      <Typography
                        variant="overline"
                        color="text.secondary"
                        sx={{
                          whiteSpace: "nowrap",
                          px: 1,
                        }}
                      >
                        {isError ? (
                          t("Error")
                        ) : (
                          <>
                            {isFetched &&
                              results?.length === 0 &&
                              t("Not found")}
                            {search && results && results?.length > 0 && (
                              <span>
                                {t("{{quantity}} result(s)", {
                                  quantity: results.length,
                                })}
                              </span>
                            )}
                          </>
                        )}
                      </Typography>
                    ),
                  },
                }}
              />
            )}
          />

          <LoadingButton
            variant="outlined"
            color="inherit"
            size="medium"
            type="submit"
            sx={{
              height: "auto",
              flexShrink: 0,
              "& .MuiButton-startIcon": {
                mr: 0,
              },
            }}
            startIcon={<SearchIcon />}
            loading={isLoading}
          />
        </Box>
      </form>
    </Box>
  );
};

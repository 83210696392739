import { Html5QrcodeResult } from "html5-qrcode";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ScanInfoSheet } from "../../../components/ScanInfoSheet";
import { CandyPage } from "../../../components/layout/CandyPage";
import { useFeedback } from "../../../hooks/useFeedback";
import { DevButtons } from "../../../components/DevButtons";
import { AuthSheet } from "../../../components/AuthSheet";
import { RemoteId } from "../../../api/AuthAPI";
import { NewScanner } from "../../../components/Scanner/NewScanner";
import { Box } from "@mui/material";

export const ARTICLE_SCAN_PAGE_ROUTE = "/scan";

const WHITELISTED_DOMAINS = ["admin.rainbowcandy.lv", "localhost:"];

const isWhitelistedUrl = (text: string) => {
  return (
    text.startsWith("http") &&
    WHITELISTED_DOMAINS.some((domain) => text.includes(domain))
  );
};

export const ArticleScanPage: FC = () => {
  const [ean, setEan] = useState<string | undefined>();
  const giveFeedback = useFeedback();
  const [link, setLink] = useState<string>();

  const remoteId = useMemo(() => {
    if (typeof link === "undefined") {
      return undefined;
    }
    const id = link.split("/authenticate/").pop() as RemoteId;
    return id;
  }, [link]);

  useEffect(() => {
    if (typeof link !== "undefined") {
      const timeout = setTimeout(() => {
        setLink(undefined);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [link]);

  const onScanSuccess = useCallback(
    (decodedText: string, decodedResult?: Html5QrcodeResult) => {
      console.info(`Code matched = ${decodedText}`, decodedResult);
      if (isWhitelistedUrl(decodedText)) {
        if (typeof link === "undefined") {
          setLink(decodedText);
          giveFeedback();
        }
        return;
      }
      if (typeof ean === "undefined") {
        setEan(decodedText);
        giveFeedback();
      }
    },
    [ean, giveFeedback, link]
  );

  const onError = useCallback((error: unknown) => {
    console.error(error);
  }, []);

  return (
    <CandyPage fullHeight fullScreen title="Scan">
      <Box
        sx={{
          display: "flex",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.appBar + 1,
        }}
      >
        <DevButtons onScanSuccess={onScanSuccess} />
        <NewScanner
          onScan={(value) => {
            onScanSuccess(value);
          }}
          onError={onError}
          paused={typeof ean !== "undefined"}
        />

        <ScanInfoSheet ean={ean} onClose={() => setEan(undefined)} />
        <AuthSheet remoteId={remoteId} onClose={() => setLink(undefined)} />
      </Box>
    </CandyPage>
  );
};

import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import TranslateIcon from "@mui/icons-material/Translate";
import QrCode2RoundedIcon from "@mui/icons-material/QrCode2Rounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { MuiIconButtonLink } from "../../../../components/MuiLink";
import { t } from "i18next";
import { generatePath } from "react-router";
import { LABEL_PRINT_PAGE_ROUTE } from "../../../LabelPrintPage";
import { TRANSLATION_ARTICLE_PAGE_ROUTE } from "../../../Translation/TranslationArticlePage/TranslationArticlePage";
import { QRCode } from "react-qrcode-logo";

interface Props {
  articleId: string;
  ean: string;
  onEdit: (value: boolean) => void;
}

export const ArticlePageActions: React.FunctionComponent<Props> = ({
  articleId,
  ean,
  onEdit,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { palette } = useTheme();

  const [showQR, setShowQR] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
      <Dialog
        open={showQR}
        onClose={() => setShowQR(false)}
        PaperProps={{
          sx: {
            background: (theme) => theme.palette.common.white,
            canvas: {
              display: "block",
            },
          },
        }}
      >
        <DialogContent>
          <QRCode
            qrStyle="dots"
            value={ean}
            size={300}
            fgColor={palette.common.black}
            bgColor="transparent"
            eyeColor={{
              inner: palette.common.black,
              outer: palette.secondary.main,
            }}
            eyeRadius={[
              [30, 30, 10, 30],
              [30, 30, 30, 10],
              [30, 10, 30, 30],
            ]}
          />
        </DialogContent>
      </Dialog>
      <Button
        startIcon={<EditRoundedIcon />}
        onClick={() => onEdit(true)}
        color="inherit"
      >
        {t("Edit")}
      </Button>

      <MuiIconButtonLink
        to={generatePath(LABEL_PRINT_PAGE_ROUTE, { articleId })}
      >
        <LocalPrintshopIcon />
      </MuiIconButtonLink>
      <MuiIconButtonLink
        to={generatePath(TRANSLATION_ARTICLE_PAGE_ROUTE, { articleId })}
      >
        <TranslateIcon />
      </MuiIconButtonLink>

      <IconButton
        id="context-button"
        aria-controls={open ? "context-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="context-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setShowQR(true)}>
          <ListItemIcon>
            <QrCode2RoundedIcon />
          </ListItemIcon>
          <ListItemText>Show EAN QR code</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

import { useCallback } from "react";
import { useNavigate } from "react-router";
import { useReconcileInventory } from "../hooks/useReconcileInventory";
import { useTranslation } from "react-i18next";
import { ConfirmButton } from "../../../../../components/ConfirmButton";
import { INVENTORY_RECONCILIATION_DASHBOARD_PAGE_ROUTE } from "../../InventoryReconciliationDashboard";
import { useSnackBar } from "../../../../../hooks/useSnackbar";

export const InventoryReconcilationDoneButton = () => {
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const { mutateAsync, isPending } = useReconcileInventory();

  const doReconcileOrder = useCallback(async () => {
    return mutateAsync(undefined, {
      onSuccess: () => {
        showSnackBar(t("Reconciliation marked as complete"), "success");
        navigate(INVENTORY_RECONCILIATION_DASHBOARD_PAGE_ROUTE);
      },
      onError: () => {
        showSnackBar(t("Failed to mark reconciliation as complete"), "error");
      },
    });
  }, [mutateAsync, showSnackBar, t, navigate]);

  return (
    <ConfirmButton
      variant="outlined"
      size="small"
      color="success"
      confirmText={t(
        "Are you sure you want to mark the reconciliation as complete? You won't be able to undo this action."
      )}
      onConfirm={() => doReconcileOrder()}
      disabled={isPending}
      sx={{ whiteSpace: "nowrap" }}
    >
      {t("Mark as done")}
    </ConfirmButton>
  );
};

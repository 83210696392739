import { FC, memo } from "react";
import { Scanner, outline } from "@yudiel/react-qr-scanner";
import { useRecoilValue } from "recoil";
import { settingsState } from "../../state/settingsState";
import { CameraSelect } from "../CameraSelect";
import "./NewScanner.scss";

interface Props {
  onScan: (value: string) => void;
  onError?: (error: unknown) => void;
  paused?: boolean;
  scanDelay?: number;
}

export const NewScanner: FC<Props> = ({
  onScan,
  onError,
  paused,
  scanDelay,
}) => {
  const { cameraDeviceId } = useRecoilValue(settingsState);

  return (
    <>
      <CameraSelect />

      <Scanner
        onScan={(result) => {
          const firstResult = result[0];
          if (firstResult) {
            onScan(firstResult.rawValue);
          }
        }}
        onError={onError}
        paused={paused}
        formats={["ean_13", "qr_code", "data_matrix"]}
        constraints={{
          facingMode: "environment",
          deviceId: cameraDeviceId,
        }}
        scanDelay={scanDelay}
        allowMultiple={true}
        components={{
          audio: false,
          torch: false,
          tracker: outline,
        }}
        styles={{
          video: {
            objectFit: "cover",
          },
        }}
        classNames={{
          container: "new-scanner-container",
          video: "new-scanner-video",
        }}
      />
    </>
  );
};

export const MemoizedNewScanner = memo(NewScanner);

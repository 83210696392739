import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticleId } from "../api/CandyAPI";
import { useAddToOrder } from "../hooks/useAddToOrder";
import { useSnackBar } from "../hooks/useSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import { orderAPI } from "../api/OrderAPI";

interface Props {
  articleId: ArticleId;
  displayName?: string;
  existingOrder?: number;
  onClose: () => void;
  isOpen: boolean;
}

export const UpdateArticlePendingOrder: FunctionComponent<Props> = ({
  articleId,
  displayName,
  existingOrder = 0,
  onClose,
  isOpen,
}) => {
  const [internalCount, setInternalCount] = useState<number>(existingOrder);
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setInternalCount(existingOrder);
  }, [existingOrder]);

  const { mutate: addToOrder } = useAddToOrder();
  const handleOk = () => {
    addToOrder(
      { articleId, count: internalCount },
      {
        onSuccess: () => {
          showSnackBar(
            t("Ordered {{quantity}} {{article}} ", {
              article: displayName,
              quantity: internalCount,
            }),
            "success"
          );
          queryClient.invalidateQueries({
            queryKey: orderAPI.fetchPendingOrder(articleId).queryKey,
          });
          handleClose();
        },
        onError: () => {
          showSnackBar(
            t("Failed to add {{quantity}} {{article}} to order", {
              article: displayName,
              quantity: internalCount,
            }),
            "error"
          );
        },
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {t("Update next order", { article: displayName || articleId })}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
        }}
      >
        <Button
          onClick={() => setInternalCount(Math.max(0, internalCount - 1))}
          size="large"
          color="secondary"
        >
          <RemoveIcon fontSize="large" />
        </Button>
        <Box>
          <Typography variant="h5">{internalCount}</Typography>
        </Box>
        <Button
          onClick={() => setInternalCount(internalCount + 1)}
          size="large"
          color="secondary"
        >
          <AddIcon fontSize="large" />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} fullWidth color="inherit">
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleOk}
          variant="contained"
          fullWidth
          color="primary"
        >
          {t("Update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticleId } from "../api/CandyAPI";
import { useBreakpointDown } from "../hooks/useBreakpoint";
import { useSnackBar } from "../hooks/useSnackbar";
import { useUpdateInventory } from "../hooks/useUpdateInventory";
import { useQueryClient } from "@tanstack/react-query";
import { inventoryAPI } from "../api/InventoryAPI";

interface Props {
  count: number;
  articleId: ArticleId;
  onClose: (count?: number) => void;
  isOpen: boolean;
}

export const EditInventoryDialog: FunctionComponent<Props> = ({
  count,
  articleId,
  onClose,
  isOpen,
}) => {
  const [internalCount, setInternalCount] = useState<number>(Number(count));
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const sm = useBreakpointDown("sm");
  const { mutate: updateInventory } = useUpdateInventory(articleId);
  const queryClient = useQueryClient();

  const handleOk = () => {
    updateInventory(internalCount, {
      onSuccess: () => {
        setInternalCount(internalCount);
        queryClient.invalidateQueries({
          queryKey: inventoryAPI.fetchInventory(articleId).queryKey,
        });
      },
      onError: () => {
        showSnackBar(t("Error updating inventory of article"), "error");
      },
    });
    onClose(internalCount);
  };

  const subtract = () => {
    setInternalCount(Math.max(internalCount - 1, 0));
  };

  const add = () => {
    setInternalCount(internalCount + 1);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      fullWidth={sm ? true : false}
    >
      <DialogTitle>{t("Set inventory quantity")}</DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
        }}
      >
        <IconButton
          disabled={internalCount === 0}
          onClick={subtract}
          size="large"
          color="secondary"
        >
          <RemoveIcon fontSize="large" />
        </IconButton>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ width: "150px" }}
            value={internalCount}
            size="medium"
            type="number"
            onChange={(e) => {
              const count = parseInt(e.target.value);
              if (Number.isNaN(count)) {
                return;
              }
              setInternalCount(count);
            }}
            variant="outlined"
          />
        </Box>
        <IconButton onClick={add} size="large" color="secondary">
          <AddIcon fontSize="large" />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} fullWidth color="inherit">
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleOk}
          variant="contained"
          fullWidth
          color="primary"
        >
          {t("Update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Document, Font, PDFViewer, Page } from "@react-pdf/renderer";
import { useCallback, useEffect, useState } from "react";
import {
  ArticleId,
  CandyArticle,
  CandySearch,
  candyAPI,
} from "../api/CandyAPI";
import { Namespace, Translation, translationApi } from "../api/TranslationApi";
import {
  CandyLabel,
  CandyPrintRow,
} from "../components/CandyPrintRow/CandyPrintRow";
import PublicSansBold from "../fonts/PublicSans-Bold.ttf";
import PublicSansItalic from "../fonts/PublicSans-Italic.ttf";
import PublicSansRegular from "../fonts/PublicSans-Regular.ttf";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import { CandyPage } from "../components/layout/CandyPage";
import RedoIcon from "@mui/icons-material/Redo";
import DeleteIcon from "@mui/icons-material/Delete";
import ArticleIcon from "@mui/icons-material/Article";
import { Search } from "../components/Search";
import { useNavigate, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { printState } from "../state/printState";
import { useAddToPrintList } from "../hooks/useAddToPrintList";
import { useRemoveFromPrintList } from "../hooks/useRemoveFromPrintList";
import { useClearPrintList } from "../hooks/useClearPrintList";
import { ConfirmButton } from "../components/ConfirmButton";
import { useTranslation } from "react-i18next";

export const LABEL_PRINT_PAGE_ROUTE = "/labels/:articleId";
export const LABEL_PRINT_PAGE_BASE_ROUTE = "/labels";

type PageParams = {
  articleId: ArticleId;
};

export const LabelPrintPage: React.FunctionComponent = () => {
  const { articleId } = useParams<PageParams>();
  const navigate = useNavigate();
  const { items } = useRecoilValue(printState);
  const addToState = useAddToPrintList();
  const removeFromState = useRemoveFromPrintList();
  const clearState = useClearPrintList();
  const { t } = useTranslation();

  Font.register({
    family: "PublicSans",
    fonts: [
      { src: PublicSansRegular },
      { src: PublicSansBold, fontWeight: "bold" },
      {
        src: PublicSansItalic,
        fontWeight: "normal",
        fontStyle: "italic",
      },
    ],
  });
  const [selectedCandy, setSelectedCandy] = useState<CandyLabel[]>([]);

  const selectCandy = useCallback(async (articleId: ArticleId) => {
    const result = await Promise.all([
      candyAPI.loadArticle(articleId),
      translationApi.loadTranslation(articleId),
    ]);
    setSelectedCandy((prev) => [...prev, createLabel(...result)]);
  }, []);

  const selectCandies = useCallback(async (articleIds: ArticleId[]) => {
    const [articles, translations] = await Promise.all([
      candyAPI.loadArticles(articleIds),
      translationApi.loadTranslations(articleIds),
    ]);

    const labels = articleIds
      .map((id) => {
        const article = articles.find((a) => a.articleId === id);
        const articleTranslations = translations.filter(
          (t) => t.articleId === id
        );

        if (!article) {
          return null;
        }
        return createLabel(article, articleTranslations);
      })
      .filter((label): label is CandyLabel => label !== null);

    setSelectedCandy((prev) => [...prev, ...labels]);
  }, []);

  const createLabel = (article: CandyArticle, translations: Translation[]) => {
    const productName =
      translations.find((t) => t.namespace === Namespace.DISPLAY_NAME)?.value ??
      article.displayName;
    const ingredients =
      translations.find((t_1) => t_1.namespace === Namespace.INGREDIENTS)
        ?.value ??
      (article.ingredients ? article.ingredients : article.ingredientsOriginal);
    return {
      candy: article,
      translations: {
        productName,
        ingredients,
      },
    };
  };

  const handleSearch = (result: CandySearch[]) => {
    if (result.length === 1) {
      selectCandy(result[0].articleId).then(() => {
        addToState(result[0].articleId);
      });
    }
  };

  const deleteLabel = (id: ArticleId) => {
    const arr = [...selectedCandy];
    const deleteIdx = arr.findIndex((k) => k.candy.articleId === id);
    if (deleteIdx === -1) {
      return;
    }
    arr.splice(deleteIdx, 1);
    setSelectedCandy(arr);
    removeFromState(id);
  };

  const refreshLabel = (id: ArticleId) => {
    deleteLabel(id);
    selectCandy(id);
    addToState(id);
  };

  const clearLabels = () => {
    setSelectedCandy([]);
    clearState();
  };

  useEffect(() => {
    if (articleId) {
      selectCandy(articleId).then(() => addToState(articleId));
      navigate(LABEL_PRINT_PAGE_BASE_ROUTE, {
        replace: true,
      });
    }
  }, [addToState, articleId, navigate, selectCandy]);

  useEffect(() => {
    if (items.length > 0) {
      setSelectedCandy([]);
      selectCandies(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCandy]);

  return (
    <CandyPage title="Print">
      <div>
        <Search onSearch={handleSearch} />
        <Divider variant="middle" sx={{ mx: 2 }} />
        <Box sx={{ m: 2 }}>
          {selectedCandy.length > 0 ? (
            <>
              <ConfirmButton
                fullWidth
                variant="outlined"
                color="secondary"
                sx={{
                  mb: 2,
                }}
                confirmText={t("Are you sure you want to clear the list?")}
                onConfirm={clearLabels}
              >
                {t("Clear list")}
              </ConfirmButton>
              <Paper elevation={2}>
                <List dense>
                  {selectedCandy.map((candy, index) => (
                    <ListItem
                      key={candy.candy.articleId + index}
                      secondaryAction={
                        <>
                          <IconButton
                            color="inherit"
                            onClick={() => refreshLabel(candy.candy.articleId)}
                          >
                            <RedoIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => deleteLabel(candy.candy.articleId)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <ArticleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={candy.translations.productName}
                        secondary={candy.candy.ean}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </>
          ) : (
            <Paper elevation={4}>
              <List dense>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ArticleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t("No labels selected")}
                    secondary={t(
                      "Search for a product to add it to the print list"
                    )}
                  />
                </ListItem>
              </List>
            </Paper>
          )}
        </Box>
      </div>

      <Divider
        variant="middle"
        sx={{
          m: 2,
        }}
      />

      <div className="pdf-wrapper">
        <PDFViewer>
          <Document>
            <Page size={"A4"} orientation="landscape">
              {selectedCandy.map((candy, index) => (
                <CandyPrintRow key={candy.candy.articleId + index} {...candy} />
              ))}
            </Page>
          </Document>
        </PDFViewer>
      </div>
    </CandyPage>
  );
};

import {
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { SortDirection } from "../../../api/Page";
import { CandyPage } from "../../../components/layout/CandyPage";
import { MuiLink } from "../../../components/MuiLink";
import { ARTICLE_PAGE_ROUTE } from "../../Article/ArticlePage/ArticlePage";
import { useTranslation } from "react-i18next";
import { useSuspendedInventory } from "../../../hooks/useInventory";
import { startTransition } from "react";
import { SortLabel } from "../../../components/table/SortLabel";
import { InventoryItem } from "../../../api/InventoryAPI";

const DEFAULT_PAGE_SIZE = 50;

export const INVENTORY_LIST_PAGE_ROUTE = "/inventory/list";

export const InventoryListPage: React.FunctionComponent = () => {
  return (
    <CandyPage fullHeight title="Inventory" skeleton={<PageSkeleton />}>
      <Inner />
    </CandyPage>
  );
};

function parseSortOrder(sortOrder: string | null) {
  if (sortOrder === null || !sortOrder) {
    return SortDirection.DESC;
  }

  if (sortOrder === "ASC") {
    return SortDirection.ASC;
  }
  return SortDirection.DESC;
}

function parseOrderBy(orderBy: string | null): keyof InventoryItem {
  if (orderBy === null || !orderBy) {
    return "currentStock";
  }

  if (
    orderBy === "currentStock" ||
    orderBy === "providerStock" ||
    orderBy === "latestOpened"
  ) {
    return orderBy;
  }
  return "currentStock";
}

const Inner: React.FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const page = parseInt(searchParams.get("page") || "0");
  const size = parseInt(
    searchParams.get("size") || DEFAULT_PAGE_SIZE.toString()
  );
  const sortOrder = parseSortOrder(searchParams.get("sortOrder"));
  const orderBy = parseOrderBy(searchParams.get("orderBy"));

  const { data: result } = useSuspendedInventory(
    page,
    size,
    sortOrder,
    orderBy
  );

  const providerStockPrint = (providerStock: number) => {
    if (providerStock < 0) {
      return "0";
    }
    if (providerStock > 100) {
      return "> 100";
    }
    return providerStock;
  };

  const updateSort = (property: keyof InventoryItem) => {
    const isAsc = orderBy === property && sortOrder === SortDirection.ASC;
    const order = isAsc ? SortDirection.DESC : SortDirection.ASC;

    startTransition(() => {
      setSearchParams({
        page: "" + page,
        size: "" + size,
        sortOrder: order,
        orderBy: property,
      });
    });
  };

  return (
    <>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t("Display Name")}</TableCell>
              <SortLabel
                label={t("Last opened")}
                sortKey="latestOpened"
                direction={sortOrder}
                orderBy={orderBy}
                updateSort={updateSort}
              />

              <SortLabel
                label={t("Provider")}
                sortKey="providerStock"
                direction={sortOrder}
                orderBy={orderBy}
                updateSort={updateSort}
                align="right"
              />
              <SortLabel
                label={t("Stock")}
                sortKey="currentStock"
                direction={sortOrder}
                orderBy={orderBy}
                updateSort={updateSort}
                align="right"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.content?.map((candy) => (
              <TableRow key={candy.articleId}>
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={generatePath(ARTICLE_PAGE_ROUTE, {
                      articleId: candy.articleId,
                    })}
                  >
                    {candy.displayName}
                  </MuiLink>
                </TableCell>
                <TableCell>{candy.latestOpened}</TableCell>
                <TableCell align="right">
                  {providerStockPrint(candy.providerStock)}
                </TableCell>
                <TableCell align="right">{candy.currentStock}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={result?.totalElements || 0}
        rowsPerPage={result?.size || DEFAULT_PAGE_SIZE}
        page={result?.number || 0}
        onPageChange={(_, page) => {
          startTransition(() => {
            setSearchParams({
              size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
              page: page.toString(),
            });
          });
        }}
        onRowsPerPageChange={(event) => {
          startTransition(() => {
            setSearchParams({
              page: searchParams.get("page") || "0",
              size: event.target.value,
            });
          });
        }}
      />
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Skeleton variant="rounded" height={90} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Skeleton variant="rounded" height={90} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Skeleton variant="rounded" height={90} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(20)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

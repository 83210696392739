import { Navigate, createBrowserRouter } from "react-router-dom";
import {
  ADMIN_USERS_PAGE,
  AdminUsersPage,
} from "../pages/Admin/AdminUsersPage";
import {
  AUTHENTICATE_PAGE_ROUTE,
  AuthenticatePage,
} from "../pages/Auth/AuthenticatePage";
import { LOGIN_PAGE_ROUTE, LoginPage } from "../pages/Auth/LoginPage";
import { REGISTER_PAGE_ROUTE, RegisterPage } from "../pages/Auth/RegisterPage";
import {
  ARTICLE_PAGE_ROUTE,
  ArticlePage,
} from "../pages/Article/ArticlePage/ArticlePage";
import {
  InventoryListPage,
  INVENTORY_LIST_PAGE_ROUTE,
} from "../pages/Inventory/InventoryListPage/InventoryListPage";
import {
  ORDER_NEXT_PAGE_ROUTE,
  OrderNextPage,
} from "../pages/Order/OrderNextPage/OrderNextPage";
import {
  ORDER_PAGE_ROUTE,
  OrderPage,
} from "../pages/Order/OrderPage/OrderPage";
import {
  ORDER_SCAN_PAGE_ROUTE,
  OrderScanPage,
} from "../pages/Order/OrderScanPage/OrderScanPage";
import {
  ORDER_LIST_PAGE_ROUTE,
  OrderListPage,
} from "../pages/Order/OrderListPage/OrderListPage";
import { CANDY_PICK_PAGE_ROUTE, CandyPickPage } from "../pages/CandyPickPage";
import {
  INVENTORY_BULK_REMOVE_PAGE_ROUTE,
  InventoryBulkRemovePage,
} from "../pages/Inventory/InventoryBulkRemoveStockPage/InventoryBulkRemovePage";
import {
  INVENTORY_BULK_ADD_PAGE,
  InventoryBulkAddPage,
} from "../pages/Inventory/InventoryBulkAddPage/InventoryBulkAddPage";
import {
  LABEL_PRINT_PAGE_BASE_ROUTE,
  LABEL_PRINT_PAGE_ROUTE,
  LabelPrintPage,
} from "../pages/LabelPrintPage";
import {
  ARTICLE_SCAN_PAGE_ROUTE,
  ArticleScanPage,
} from "../pages/Article/ArticleScanPage/ArticleScanPage";
import {
  TRANSLATION_ARTICLE_PAGE_ROUTE,
  TranslationArticlePage,
} from "../pages/Translation/TranslationArticlePage/TranslationArticlePage";
import {
  TranslationPage,
  TRANSLATION_PAGE_ROUTE,
} from "../pages/Translation/TranslationPage";
import { AppRoot } from "./AppRoot";
import { NotFoundPage } from "./NotFoundPage";
import {
  InventoryNoStockPage,
  INVENTORY_NO_STOCK_PAGE_ROUTE,
} from "../pages/Inventory/InventoryNoStockPage/InventoryNoStockPage";
import {
  WEBSHOP_PRODUCTS_PAGE_ROUTE,
  WebshopProductsPage,
} from "../pages/Webshop/WebshopProductsPage";
import { ErrorPage } from "./ErrorPage";
import {
  INVENTORY_LOG_PAGE_ROUTE,
  InventoryLogPage,
} from "../pages/Inventory/InventoryLogPage/InventoryLogPage";
import {
  PIECE_PRICE_PAGE_ROUTE,
  PiecePricesPage,
} from "../pages/PiecePricesPage/PiecePricesPage";
import { DashboardPage } from "../pages/Dashboard/DashboardPage";
import {
  ARTICLE_LIST_PAGE_ROUTE,
  ArticleListPage,
} from "../pages/Article/ArticleListPage/ArticleListPage";
import {
  INVENTORY_DASHBOARD_PAGE_ROUTE,
  InventoryDashboardPage,
} from "../pages/Inventory/InventoryDashboardPage";
import {
  ORDER_DASHBOARD_PAGE_ROUTE,
  OrderDashboardPage,
} from "../pages/Order/OrderDashboardPage";
import {
  INVENTORY_RECONCILIATION_PAGE_ROUTE,
  InventoryReconciliationPage,
} from "../pages/Inventory/InventoryReconciliation/InventoryReconciliationPage/InventoryReconciliationPage";
import {
  INVENTORY_RECONCILIATION_DASHBOARD_PAGE_ROUTE,
  InventoryReconciliationDashboardPage,
} from "../pages/Inventory/InventoryReconciliation/InventoryReconciliationDashboard";
import {
  INVENTORY_RECONCILIATION_HISTORY_PAGE_ROUTE,
  InventoryReconciliationHistoryPage,
} from "../pages/Inventory/InventoryReconciliation/InventoryReconciliationHistoryPage/InventoryReconciliationHistoryPage";
import {
  INVENTORY_RECONCILIATION_HISTORY_LIST_PAGE_ROUTE,
  InventoryReconciliationHistoryListPage,
} from "../pages/Inventory/InventoryReconciliation/InventoryReconciliationHistoryListPage/InventoryReconciliationHistoryListPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppRoot />,
    ErrorBoundary: ErrorPage,
    children: [
      {
        index: true,
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: TRANSLATION_ARTICLE_PAGE_ROUTE,
        element: <TranslationArticlePage />,
      },
      {
        path: TRANSLATION_PAGE_ROUTE,
        element: <TranslationPage />,
      },
      {
        path: LABEL_PRINT_PAGE_ROUTE,
        element: <LabelPrintPage />,
      },
      {
        path: LABEL_PRINT_PAGE_BASE_ROUTE,
        element: <LabelPrintPage />,
      },
      {
        path: ARTICLE_LIST_PAGE_ROUTE,
        element: <ArticleListPage />,
      },
      {
        path: ARTICLE_PAGE_ROUTE,
        element: <ArticlePage />,
      },
      {
        path: ARTICLE_SCAN_PAGE_ROUTE,
        element: <ArticleScanPage />,
      },
      {
        path: CANDY_PICK_PAGE_ROUTE,
        element: <CandyPickPage />,
      },
      {
        path: ORDER_DASHBOARD_PAGE_ROUTE,
        element: <OrderDashboardPage />,
      },
      {
        path: ORDER_NEXT_PAGE_ROUTE,
        element: <OrderNextPage />,
      },
      {
        path: ORDER_SCAN_PAGE_ROUTE,
        element: <OrderScanPage />,
      },
      {
        path: ORDER_LIST_PAGE_ROUTE,
        element: <OrderListPage />,
      },
      {
        path: ORDER_PAGE_ROUTE,
        element: <OrderPage />,
      },
      {
        path: INVENTORY_BULK_ADD_PAGE,
        element: <InventoryBulkAddPage />,
      },
      {
        path: INVENTORY_BULK_REMOVE_PAGE_ROUTE,
        element: <InventoryBulkRemovePage />,
      },
      {
        path: INVENTORY_DASHBOARD_PAGE_ROUTE,
        element: <InventoryDashboardPage />,
      },
      {
        path: INVENTORY_LIST_PAGE_ROUTE,
        element: <InventoryListPage />,
      },
      {
        path: INVENTORY_NO_STOCK_PAGE_ROUTE,
        element: <InventoryNoStockPage />,
      },
      {
        path: INVENTORY_LOG_PAGE_ROUTE,
        element: <InventoryLogPage />,
      },
      {
        path: INVENTORY_RECONCILIATION_DASHBOARD_PAGE_ROUTE,
        element: <InventoryReconciliationDashboardPage />,
      },
      {
        path: INVENTORY_RECONCILIATION_PAGE_ROUTE,
        element: <InventoryReconciliationPage />,
      },
      {
        path: INVENTORY_RECONCILIATION_HISTORY_LIST_PAGE_ROUTE,
        element: <InventoryReconciliationHistoryListPage />,
      },
      {
        path: INVENTORY_RECONCILIATION_HISTORY_PAGE_ROUTE,
        element: <InventoryReconciliationHistoryPage />,
      },
      {
        path: ADMIN_USERS_PAGE,
        element: <AdminUsersPage />,
      },
      {
        path: WEBSHOP_PRODUCTS_PAGE_ROUTE,
        element: <WebshopProductsPage />,
      },
      {
        path: PIECE_PRICE_PAGE_ROUTE,
        element: <PiecePricesPage />,
      },
      {
        path: "/admin",
        element: <Navigate to={ADMIN_USERS_PAGE} />,
      },
    ],
  },
  {
    path: LOGIN_PAGE_ROUTE,
    element: <LoginPage />,
  },
  {
    path: REGISTER_PAGE_ROUTE,
    element: <RegisterPage />,
  },
  {
    path: AUTHENTICATE_PAGE_ROUTE,
    element: <AuthenticatePage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
